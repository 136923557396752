import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface Props extends BoxProps {
  color: Queries.DatoCmsColorField
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<Props>(({ color, theme }) => ({
  backgroundColor: '#999999',
  width: '100%',
  height: '4px',
  ...(color &&
    color.red &&
    color.green &&
    color.blue && {
      backgroundColor: `rgb(${color.red}, ${color.green}, ${color.blue})`,
    }),
}))

const CardColorStrip = ({ ...props }: Props) => {
  return <StyledBox {...props} />
}

export default CardColorStrip
