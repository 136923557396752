import React from 'react'
import { graphql } from 'gatsby'

import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import useDynamicData from '_core/helpers/useDynamicData'

import NewsCard from 'public/components/molecules/cards/news/NewsCard'

import LandingPage, {
  LandingPageContextProps,
} from 'public/templates/layouts/LandingPage'

interface Props {
  data: Queries.NewsLandingQuery
  pageContext: LandingPageContextProps
}

const NewsLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const newsItems = data.allDatoCmsIndustryNews.edges

  const { isDynamic, filterBarProps, contentProps, paginationProps } =
    useDynamicData({
      ...pageContext,
      dynamicType: 'newsItem',
      dynamicContentQuery: dynamicContentQuery,
      dataResultSelector: 'newsItems',
      withTerms: true,
      withFilterBar: true,
    })

  return (
    <LandingPage
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
      heroProps={{
        mainHeading: landing.heroMainHeading,
        smallHeading: landing.heroSmallHeading,
        imagePortrait: landing.imagePortrait.gatsbyImageData,
        imageLandscape: landing.imageLandscape.gatsbyImageData,
      }}
      pageContext={pageContext}
      filterBarProps={{ ...pageContext, ...filterBarProps }}
      paginationProps={{ ...pageContext, ...paginationProps }}
      summaryItemName="news items"
      pageNum={pageContext.currentPage}
    >
      {isDynamic == true &&
      contentProps &&
      contentProps.items &&
      contentProps.items.length > 0 ? (
        <div className="dynamic-content">
          <MasonryGrid className="masonry-dynamic">
            {contentProps.items.map((newsItem: Queries.DatoCmsIndustryNews) => {
              return (
                <NewsCard
                  key={`d-nc-${newsItem.node.originalId}`}
                  newsData={newsItem.node}
                  isDynamic
                />
              )
            })}
          </MasonryGrid>
        </div>
      ) : (
        <MasonryGrid className="masonry-static">
          {newsItems.map((newsItem: Queries.DatoCmsIndustryNews) => {
            return (
              <NewsCard
                key={`d-nc-${newsItem.node.originalId}`}
                newsData={newsItem.node}
              />
            )
          })}
        </MasonryGrid>
      )}
    </LandingPage>
  )
}

export const pageQuery = graphql`
  query NewsLanding($skip: Int!, $perPage: Int!) {
    datoCmsLandingPage(originalId: { eq: "1370552" }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsIndustryNews(
      limit: $perPage
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...News
        }
      }
    }
  }
`

const dynamicContentQuery = `
query($filter: NewsItemFilter, $pagination: PaginationOptions!) {
    newsItems(filter: $filter, pagination: $pagination) {
      totalCount
      edges {
        node {
          title
          date
          url
          id
          source {
            name
          }
      levels {
        name
        slug
        id
        _modelApiKey
      }
      locations {
        name
        slug
        id
        _modelApiKey
      }
      regions {
        name
        slug
        id
        _modelApiKey
      }
      departments {
        slug
        name
        id
        _modelApiKey
        departmentColor {
          red
          green
          blue
        }         
      }
        }
      }
    }

  }
`

export default NewsLanding
