import React, { memo } from 'react'
import { Box, BoxProps } from '@mui/material'

const CardHeading = (props: BoxProps) => (
  <Box
    component="h3"
    typography="h3"
    fontSize={{ xs: '2.5rem', lg: '2.8rem' }}
    lineHeight={1}
    pb="1.6rem"
    {...props}
  />
)

export default memo(CardHeading)
