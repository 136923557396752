import React, { memo } from 'react'
import { Box, Divider, styled } from '@mui/material'

import Card from '_core/components/atoms/cards/Card'
import CardColorStrip from '_core/components/atoms/cards/CardColorStrip'
import CardHeading from '_core/components/atoms/cards/CardHeading'
import Flex from '_core/components/atoms/layout/Flex'
import ExternalLink from '_core/components/atoms/links/ExternalLink'
import ExternalButtonLink from '_core/components/atoms/buttons/ExternalButtonLink'
import TermButtonGroup from '_core/components/molecules/buttonGroups/TermButtonGroup'

import TimeAgo from 'public/components/atoms/date/TimeAgo'

interface Props {
  whiteBg?: true
  isDynamic?: boolean
  newsData: Queries.DatoCmsIndustryNews
}

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.masonrySpacing,
}))

const NewsCard = ({ newsData, isDynamic, whiteBg }: Props) => {
  let showTerms = false
  if (
    newsData.departments.length ||
    newsData.regions.length ||
    newsData.levels.length ||
    newsData.locations.length
  ) {
    showTerms = true
  }

  const color =
    newsData.departments && newsData.departments.length > 0
      ? newsData.departments[0].departmentColor
      : {}

  return (
    <StyledCard whiteBg={whiteBg}>
      <CardColorStrip color={color} />
      <Box p={2}>
        <ExternalLink href={newsData.url}>
          <CardHeading sx={{ m: 0 }}>{newsData.title}</CardHeading>
        </ExternalLink>
        <TimeAgo date={newsData.date} />

        {showTerms && (
          <Box pt={2}>
            <TermButtonGroup
              keyId={newsData.originalId}
              isDynamic={isDynamic}
              terms={{
                departments: newsData.departments,
                regions: newsData.regions,
                levels: newsData.levels,
                locations: newsData.locations,
              }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ px: 2, pb: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <Flex>
          <ExternalButtonLink
            href={newsData.url}
            fullWidth
            aria-label={`visit article: ${newsData.title}`}
            buttonProps={{
              color: 'secondary',
              sizeVariant: 'md',
              strongLabel: true,
              darkLabel: true,
              'aria-label': `Click to visit article: ${newsData.title}`,
            }}
          >
            Read More
          </ExternalButtonLink>
        </Flex>
      </Box>
    </StyledCard>
  )
}

export default memo(NewsCard)
